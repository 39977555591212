<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <v-img style="max-width: 400px" src="/img/assignments/mice.png" />
      </p>

      <p class="mb-2">
        The mice shown represent a sample of mice captured from a part of a field. Are fat mice more
        likely to have black tails and thin mice more likely to have white tails?
      </p>
      <p class="mb-4">
        <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options1"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>

      <h3><u>Reason</u></h3>
      <p class="mb-2">
        <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options2"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'UPEIToTLItem7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
      options2: [
        {
          text: '8/11 of the fat mice have black tails and 3/4 of the thin mice have white tails.',
          value: 'one',
        },
        {
          text: 'Some of the fat mice have white tails and some of the thin mice have white tails.',
          value: 'two',
        },
        {
          text: '18 mice out of thirty have black tails and 12 have white tails.',
          value: 'three',
        },
        {
          text: 'Not all of the fat mice have black tails and not all of the thin mice have white tails.',
          value: 'four',
        },
        {
          text: '6/12 of the white tailed mice are fat.',
          value: 'five',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
